export default {
  SOMETHING_WENT_WRONG_MSG: 'Something went wrong please try again.',
  appBaseUrl: 'https://api.hdmk.net/api/',
  // appBaseUrl: 'https://api-sandbox.hdmk.net/api/',
// appBaseUrl: 'http://192.168.1.35:90/api/',
  // Action Buttons
  edit: 'edit',
  reschedule: 'reschedule',
  complete: 'complete',
  close: 'close',

  // Inspection status
  cancel: 'cancel',
  completed: 'completed',
  accept: 'accept',
  decline: 'decline',
  pending: 'pending',

  // Availability Status
  booked: 'booked',
  available: 'available',
  unavailable: 'unavailable',

  transfer: 'transfer',

  MainInspector: 'main_inspector',
  SubInspector: 'sub_inspector',

  paymentReminder: 'payment',
  agreementReminder: 'agreement',

  roles: {
    admin: 'admin',
    manager: 'manager',
    asst_manager: 'asst_manager'
  },

  regions: {
    louisiana: 'louisiana',
    colorado: 'colorado',
    georgia: 'georgia',
    texas: 'texas',
    florida: 'florida',
    mississippi: 'mississippi',
    louisiana_e: 'louisiana_e'
  },

  s3: {
    folders: {
      inspectors: {
        profile_images: 'inspectors/profile_images',
        signatures: 'inspectors/signatures',
        full_body_images: 'inspectors/full_body_images',
        headshot_images: 'inspectors/headshot_images',
        avtar_images: 'inspectors/avtar_images'
      }
    }
  }
}
